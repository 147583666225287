import { useState, useEffect, useCallback } from 'react';
import { useAuthProvider } from 'react-admin';
import isEqual from 'lodash/isEqual';

import { listener } from '../../../utils/eventshub';

const { listen, unlisten } = listener;

export const useAppAuth = () => {
  const authProvider = useAuthProvider();

  const [authData, setAuthData] = useState(null);

  const updateAuthData = useCallback(
    async () => {
      if (!authProvider) {
        return;
      }
      const loggedIn = authProvider?.loggedIn();
      const username = authProvider?.getIdentity();
      // COMMENT : though we are aware it simply gets from localStorage, we keep it API safe.
      const permissions = await authProvider?.getPermissions();
      const permissionsSet = new Set(permissions);
      const authDataUpdate = {
        loggedIn,
        username,
        permissionsSet,
        permissions,
      };
      setAuthData(
        prevAuthData =>
          isEqual(prevAuthData, authDataUpdate) ? prevAuthData : authDataUpdate,
      );
    },
    [authProvider],
  );

  useEffect(
    () => {
      if (!authProvider || !updateAuthData) {
        return () => {};
      }

      const authListener = {
        channel: 'auth',
        listenerName: 'useAppAuth',
        callback: updateAuthData,
      };

      listen(authListener);

      const asyncCall = async () => {
        // COMMENT : though not necessary, we handle the promise and call it with await.
        await updateAuthData();
      };
      asyncCall();

      return () => {
        unlisten(authListener);
      };
    },
    [authProvider, updateAuthData],
  );

  return { authData };
};

export default useAppAuth;
