/**
 * A straightforward observable pattern events hub duplex multi-channels/event types implementation.
 * Initially placed under sync-manager (as this is the dev starting point), usually placed under infra / common dir or similiar.
 */
/**
 * Creates an events hub object that allows registering listeners for specific channels and dispatching events to those listeners.
 *
 * @returns {Object} The events hub object.
 */
export const createEventsHub = () => {
  const channels = new Map();
  const DISPATCH_TARGET_ALL = '*';

  /**
   * Receive a callback and creates a throttled callback function that aggregates events and invokes the wrapped callback only once per specified throttle settings.
   *
   * @param {Object} params - The parameters for creating the throttled callback.
   * @param {string} params.listenerName - The name of the listener.
   * @param {Object} params.throttle - The throttle configuration.
   * @param {number} params.throttle.milliThrottle - The amount of time (in milliseconds) that needs to pass before the callback can be invoked again.
   * @param {number} params.throttle.maxEventsThrottle - The maximum number of events to accumulate before invoking the callback.
   * @param {Function} params.callback - The function to be throttled.
   * @returns {Function} A new function that wraps the callback with throttling behavior.
   */
  const createThrottledCallback = ({
    listenerName,
    throttle: { milliThrottle, maxEventsThrottle },
    callback,
  }) => {
    let events = [];

    const throttledCallback = event => {
      if (events.length >= maxEventsThrottle) {
        console.log(`max events buffer exceeded', identifier: ${listenerName}`);
        return;
      }

      /**
       * Triggers the callback with the accumulated events buffer.
       */
      // TODO: add dispose clear interval functionality.
      setInterval(() => {
        if (events?.length > 0 && callback) {
          callback(events);
        }
        events = [];
      }, milliThrottle);

      events.push(event);
    };

    return throttledCallback;
  };

  /**
   * Registers a listener for a specific channel.
   *
   * @param {Object} params - The params for registering the listener.
   * @param {string} params.channel - The channel to listen to. Defaults to 'default'.
   * @param {Function} params.callback - The callback function to be invoked when an event is dispatched to the channel.
   * @param {string} params.listenerName - The name of the listener. Defaults to 'empty'.
   * @param {Object} params.throttle - The throttle configuration for the listener. If provided, the callback will be throttled.
   */
  const listen = ({
    channel = 'default',
    callback,
    listenerName = 'empty',
    throttle = undefined,
  }) => {
    let channelListeners = channels.get(channel);
    if (!channelListeners) {
      channelListeners = [];
      channels.set(channel, channelListeners);
    }
    if (!throttle) {
      channelListeners.push({
        name: listenerName,
        callback,
      });
    } else {
      const throttledCallback = createThrottledCallback({
        listenerName,
        throttle,
        callback,
      });
      channelListeners.push({
        name: listenerName,
        callback: throttledCallback,
      });
    }
  };

  /**
   * Unregisters a listener from a specific channel.
   *
   * @param {Object} params - The params for unregistering the listener.
   * @param {string} params.channel - The channel to unregister from.
   * @param {Function} params.listener - The listener function to be unregistered.
   */
  const unlisten = ({ channel, callback }) => {
    if (channels.has(channel)) {
      let channelListeners = channels.get(channel);
      if (channelListeners) {
        channelListeners = [
          ...channelListeners.filter(
            ({ filterCallback }) => filterCallback !== callback,
          ),
        ];
      }
    }
  };

  /**
   * Dispatches an event to the listeners of a specific channel.
   *
   * @param {*} event - The event to dispatch.
   * @param {string} event.channel - The channel to dispatch the event to. Defaults to 'default'.
   * @param {string} event.source - The caller identifier of the event.
   * @param {string} event.target - The target listener name. If '*', the event will be dispatched to all listeners on the channel.
   * @param {*} event.data - The event data.
   */
  const dispatch = event => {
    const { source, channel = 'default', target = DISPATCH_TARGET_ALL } = event;
    if (!source) {
      const errorMessage = 'Dispatch event source required';
      const error = new Error(errorMessage);
      console.error(errorMessage);
      throw error;
    }

    const channelListeners = channels.get(channel);
    if (channelListeners) {
      channelListeners.forEach(listener => {
        const { callback, name: listenerName } = listener;
        // todo - refactor name to listenerName.
        if (target === '*' || target === listenerName) {
          callback(event);
        }
      });
    }
  };

  return { listen, unlisten, dispatch };
};

const globalEventsHub = createEventsHub();

const { listen, unlisten } = globalEventsHub;

export const listener = { listen, unlisten };

const { dispatch } = globalEventsHub;

export const dispatcher = { dispatch };
