/* eslint-disable */
import { couchConfig } from '../../couch/config';
import { createPouchAuth } from '../../couch/pouchDbAPI';
import { logger } from '../../utils/logger';

const { d, v, i } = logger({ source: 'authProvider' });
const { url } = couchConfig;
const {
  logIn: pouchLogin,
  logOut: pouchLogout,
  getSession: pouchGetSession,
  localUserData: pouchLocalUserData,
} = createPouchAuth({
  remoteServer: url,
});
import { dispatcher } from '../../utils/eventshub';
export const AUTH_CHANNEL = 'auth';
export const AUTH_EVENT_TYPE = 'auth';
export const AUTH_PROVIDER_EVENT_SOURCE = 'pouchDb';
const { dispatch } = dispatcher;

export const buildUserId = ({ username }) => {
  const _id = `org.couchdb.user:${username}`;
  return _id;
};

//TODO: Need a review - add offline support ??? - some current fields such as salt are not present in practice.

//TODO: authProvider + background interval session check & retry, still not exported, under dev - unit test required, collaberation commit.

// COMMENT : while auth provider is built by RA interface, it is external to it and does not rely on it - uses auth API and local storage cache.
// TOOD: would be better to build using typescript interface.
export const createAuthProvider = ({
  authApi,
  sessionCheckDelta = 10 * 1000,
  sessionCheckFailRetryDelta = 5 * 1000,
  sessionCheckRetries = 6,
}) => {
  //We set it as let and const to enable swapping & mocking those methods with the __testApi__ methods.

  let {
    login: apiLogIn,
    logout: apiLogOut,
    getSession: apiGetSession,
  } = authApi;

  let lastLoginState;

  v({
    msg: 'Auth provider configuration',
    data: {
      sessionCheckDelta,
      sessionCheckFailRetryDelta,
      sessionCheckRetries,
    },
  });

  let backgroundAuthCheckInterval;

  const startBackgroundSessionCheck = () => {
    const LOOP_INTERVAL = 5 * 1000;
    let sessionCheckRetriesCount = 0;
    let lastBackgroundSessionValidateTime = 0;
    let lastRetryValidateTime = 0;

    //We check for last update delta as the context can also be updated by login or logout calls made in parallel to the session auth test calls.
    const lastSessionValidationIntervalPassed = now => {
      const updateDelta = now - lastBackgroundSessionValidateTime;
      const retval = updateDelta >= sessionCheckDelta;
      return retval;
    };

    const updateLastSessionValidationTime = now => {
      lastBackgroundSessionValidateTime = now;
    };

    const lastRetryValidationIntervalPassed = now => {
      const updateDelta = now - lastRetryValidateTime;
      const retval = updateDelta >= sessionCheckFailRetryDelta;
      return retval;
    };

    const updateRetryValidationIntervalPassed = now => {
      lastRetryValidateTime = now;
    };

    const safeGetSession = async () => {
      let retval;
      try {
        retval = await apiGetSession();
      } catch (e) {
        retval = { ok: false, userCtx: undefined };
      }
      return retval;
    };

    backgroundAuthCheckInterval = setInterval(async () => {
      /**
       * In case we are offline we want to enable the user to continue using the app hence
       * we would not perform a session validation in-front of the server
       * and we will reset the retries count to 0 enabling a full retry check
       * once we go online back - possible case of on-line / offline / on-line / off-line before the retries
       * count reached session termination but we are not bothered by this edge case .
       */
      d({ msg: 'background loop' });
      if (!online()) {
        sessionCheckRetriesCount = 0;
        return;
      }
      const now = Date.now();

      const updateDeltaPassed = lastSessionValidationIntervalPassed(now);
      const retryMode = sessionCheckRetriesCount > 0;
      const retryIntervalPassed = retryMode
        ? lastRetryValidationIntervalPassed(now)
        : false;

      const performRertry = retryMode && retryIntervalPassed;
      d({
        msg: 'background loop data',
        data: {
          now,
          updateDeltaPassed,
          retryMode,
          retryIntervalPassed,
          performRertry,
          sessionCheckRetriesCount,
        },
      });
      if (updateDeltaPassed || performRertry) {
        d({
          msg: 'time delta or retry',
          data: { updateDeltaPassed, performRertry },
        });

        const { ok, name, roles } = await safeGetSession();
        d({
          msg: 'time delta or retry',
          data: { updateDeltaPassed, performRertry, ok, name, roles },
        });
        const sessionCheckResult = ok && name && roles;
        if (!sessionCheckResult) {
          sessionCheckRetriesCount++;
          if (sessionCheckRetriesCount >= sessionCheckRetries) {
            d({ msg: 'logout' });
            logout();
          }
        } else {
          d({ msg: 'session call succeed, retry count back to 0' });
          sessionCheckRetriesCount = 0;
          updateLastSessionValidationTime(now);
          updateRetryValidationIntervalPassed(now);
        }
      }
    }, LOOP_INTERVAL);
  };
  const online = () => window.navigator.onLine;

  const login = async ({ username, password }) => {
    if (online()) {
      const { ok, roles, name, message, awsAccessToken } = await apiLogIn({
        username,
        password,
      });

      if (!ok) {
        throw new Error(message || 'Authentication failed');
      }
      // console.debug('Successfully logged in, trying to check for user data');
      // TODO: validate if besides indexdb we need also local storage.
      localStorage.setItem('username', name);
      localStorage.setItem('permissions', JSON.stringify(roles));
      d({ msg: 'Starting authProvider background loop' });
      !backgroundAuthCheckInterval && startBackgroundSessionCheck();
      const userId = buildUserId({ name });
      dispatch({
        channel: AUTH_CHANNEL,
        source: AUTH_PROVIDER_EVENT_SOURCE,
        type: AUTH_EVENT_TYPE,
        data: {
          loginState: 'loggedIn',
          username: name,
          permissions: roles,
          userId,
          awsAccessToken,
        },
      });
    } else {
      //We keep the commented code to more easily return to the offline task in the future rather then searching on what commit it has been removed.
      // console.debug('Offline, trying to find user');
      // Offline, must try to auth against local cache
      try {
        // TODO: add local db offline mode login.
        // const existingUser = await offlineUsers.get(
        //   `org.couchdb.user:${username}`,
        // );
        // // console.debug('Found existing user, checking hash: ', existingUser);
        // await new Promise((resolve, reject) => {
        //   // TODO: not sure from where it gest the salt.
        //   couchPwd.hash(password, existingUser.salt, (err, hash) => {
        //     if (err) {
        //       return reject(err);
        //     }
        //     // TODO: could not find from where it gets derived key.
        //     if (hash !== existingUser.derived_key) {
        //       return reject(new Error('Incorrect password for offline user'));
        //     }
        //     return resolve();
        //   });
        //});
        // TODO : validate if also local storage is required - if we use pouch local indexed db we can keep drawing the roles from it.
        //localStorage.setItem('permissions', JSON.stringify(existingUser.roles));
      } catch (e) {}
    }
  };

  const logout = async () => {
    const username = localStorage.getItem('username');
    const userId = buildUserId({ name: username });
    const roles = localStorage.getItem('permissions');
    try {
      localStorage.removeItem('username');
      localStorage.removeItem('permissions');
      stopBackgroundSessionCheck();
      await apiLogOut();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        channel: AUTH_CHANNEL,
        source: AUTH_PROVIDER_EVENT_SOURCE,
        type: AUTH_EVENT_TYPE,
        data: {
          loginState: 'loggedOut',
          username,
          permissinos: roles,
          userId,
        },
      });
      return;
    }
  };

  //TODO : set last background check error for RA checkError method.
  const checkError = () => Promise.resolve();

  const checkAuth = () => {
    const isLoggedIn = loggedIn();
    if (isLoggedIn) {
      !backgroundAuthCheckInterval && startBackgroundSessionCheck();
      return Promise.resolve();
    } else {
      i({ msg: 'Auth check false.' });
      return Promise.reject();
    }
  };

  const getPermissions = () => {
    const permissionsJSON = localStorage.getItem('permissions');
    const permissions = permissionsJSON && JSON.parse(permissionsJSON);
    const retval = permissions
      ? Promise.resolve(permissions)
      : Promise.reject();
    return retval;
  };

  const getIdentity = () => {
    const username = localStorage.getItem('username');
    return username;
  };

  const stopBackgroundSessionCheck = () => {
    clearInterval(backgroundAuthCheckInterval);
  };

  const loggedIn = () => {
    const username = localStorage.getItem('username');
    const permissions = localStorage.getItem('permissions');
    const authValid = !!(username && permissions);
    return authValid;
  };

  return {
    login,
    logout,
    loggedIn,
    checkError,
    checkAuth,
    getPermissions,
    getIdentity,
  };
};
